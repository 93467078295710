// Colors

$primary-color:#82002a;
$white-color:#FFFFFF;
$gray-1-color:#58595B;
$gray-2-color:#6D6E71;
$gray-3-color:#EFEEED;
$yellow-color:#B28243;


.c-primary{
  color: $primary-color; 
}
.c-white{
  color: $white-color;
}
.c-gray-1{
  color: $gray-1-color;
}
.c-gray-2{
  color: $gray-2-color;
}
.c-gray-3{
  color: $gray-3-color;
}
.c-yellow{
  color: $yellow-color;
}

.bg-primary{
  color: $primary-color;
}
.bg-white{
  color: $white-color;
}
.bg-gray-1{
  color: $gray-1-color;
}
.bg-gray-2{
  color: $gray-2-color;
}
.bg-gray-3{
  color: $gray-3-color;
}
.bg-yellow{
  color: $yellow-color;
}