.frase{
  position: relative;
  
  @include responsive(s){
    padding: 0 1rem;
  }
  a {
    position: absolute;
    left: 0px;
  }
  .container{
    padding: 40px 0;
  }
  .h3{
    color: $primary-color;
    text-transform: uppercase;
    font-size:1.5vw;;
    text-align: center;
    font-weight: 100;
    height:6vw;
    vertical-align: middle;
    line-height: 5.5vw;
  }
}
.quemsomos{
  position: relative;
  p{
    margin-bottom: 10px;
  }

  &::before{
    content: "";
    display: block;
    width: 45%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    @include responsive(s){
      position: relative;
      width: 150px;
      height: 150px;
      margin: 0 auto;
      top: 3rem;
      margin-bottom: 20px;
    }
  }
  
  .row{
    @include responsive(s){
    text-align: center;
  }
}
}
.atuacao{
  //min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  &::before{
    content: "";
    display: none;
    width: 50%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 10%;
    z-index: 1;
    mix-blend-mode: multiply;
    @include responsive(s){
      width: 33%;
      height: 41%;
    }
  }
  &::after{
    content: "";
    display: none;
    width: 50%;
    height: 50%;
    mix-blend-mode: multiply;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    mix-blend-mode: multiply;
    @include responsive(s){
      width: 33%;
      height: 41%;
      bottom: -9%;
    }
  }
  .container{
    width: 100%;
    position: relative;
    z-index: 8; 
  }
  .container-fluid{
    padding-top: 12.3rem;
  }
  img{
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;
    opacity: .7;
  }
  .actings-lines p{
    line-height: 50px;
  }
  .end{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
    align-content: space-around;
    
    
    justify-content: center;
    @include responsive(s){
      min-height: initial;
      text-align: center;
      align-items: center !important;
      width:100%;
      &:first-child{
        margin-bottom: 3rem;
      }
      &:last-child{
        margin-top: 3rem;
      }
    }
  }
  .all-actings-top p{
    &:nth-of-type(even) {
      color:  $primary-color !important;
    }
    &:nth-of-type(odd) {
      color: $gray-2-color !important;
    }
  }

  .all-actings-down p{
    &:nth-of-type(odd) {
      color: $gray-2-color;
    }
    &:nth-of-type(even) {
      color:  $primary-color;
    }
  }
  p{
    @include typeface-2(p1, desktop);
    line-height: 180%;
    @include responsive(s){
      @include typeface-2(p1, mobile);
      line-height: 180%;
    }
    
    a{
      color: currentColor;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  h2{
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive(l){
      min-height: 100px;
    }
    @include responsive(m){
      min-height: 70px;
    }
    @include responsive(s){
      min-height: initial;
    }
  }

}




.professional{
  position: relative;
.swiper-professionals{
  position: relative;
}
  .swiper{
    width: 70vw;
    margin-top:.2vw;
  }
  .swiper-button-prev, .swiper-button-next{
    &::after{
      content: "";
      background-size: 100% !important;
      width: .8vw;
      height: 28px;
      background-repeat: no-repeat !important;
      // margin-top:5vw;
  }
  }
  .btsslide{
    position: absolute;
    top: 14.5vw;
    display: flex;
    width: 76vw;
    flex-direction: row;
    justify-content: space-between;
}
  .swiper-button-prev{
    position: initial !important;
    margin:0;
    background-color: transparent;
    border:0;
    &::after{
      background: url(../assets/svg/prev-gray.svg);
    }
    @media (max-width:991px) {
      width: 10px;
    }
    @media (max-width:720px) {
      height: 17%;
    }
    @media (max-width:650px) {
      height: 21%;
      left: 11vw;
    }
    @media (max-width:540px) {
      height: 25%;
    }
    
  }
  .swiper-button-next{
    background-color: transparent;
    margin: 0;
    position: initial !important;
    border:0;
    &::after{
      background: url(../assets/svg/next-gray.svg);
    }
    @media (max-width:991px) {
      width: 10px;
    }
    @media (max-width:720px) {
      height: 17%;
    }
    @media (max-width:650px) {
      height: 21%;
      right: 12.8vw;

    }
    @media (max-width:540px) {
      height: 25%;
    }
  }

  &::before{
    content: "";
    display: none;
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    mix-blend-mode: multiply;
    @include responsive(s){
      width: 33%;
    
      height: 35%;
      display: none;
    }
  }
  &::after{
    content: "";
    display: none;
    width: 50%;
    height: 50%;
    mix-blend-mode: multiply;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    mix-blend-mode: multiply;
    @include responsive(s){
      width: 33%;
      height: 35%;
      display: none;
    }
  }
  
  h2{
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 50px;
    margin-left:-5px;
    @media (max-width: 970px){
      margin-bottom: 20px;
    }
  }
  .formbusca{
    padding: 2rem;
    margin-left: 26px;
    margin-top: 12rem;
    @include responsive(s){
      width: 100%;
      margin: 0;
    }
  }
  .form{
    width: 400px;
    position: relative;
    @include responsive(s){
      width: 100%;
    }
    input{
      border-bottom: 1px solid $primary-color !important; 
      color: $gray-1-color;
      font-size: 1vw;
      background: transparent;
      position: relative;
      width: 30vw;
      border: 0;
      padding: 12px 16px;
      padding-left: 40px;
      outline: 0;
      transition: .5s ease;
      &::placeholder{
        color: $primary-color;
      }
      &:hover, &:focus{
        color: $gray-1-color;
        border-color: $gray-1-color !important;
      }
    }
    button{
      max-width: 25px;
      height: 25px;
      position: absolute;
      background: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      @media (max-width:950px) {
        max-width: 18px;
        height: 20px;
      }
      svg{
        width: 100%;
        height: auto;
        path{
          fill:$primary-color;
        }
      }
    }
  }
  .swiper{
    .item-swipper-6 a,
    .item-swipper-14 a,
    .item-swipper-15 a,
    .item-swipper-12 a,
    .item-swipper-13 a,
    .item-swipper-7 a{
      &::before {
        left: -10.5vw;
        touch-action: none;
        width: 10vw;
        pointer-events: none;
        text-align: right !important;
        
      }
    }
   
    a{
      position: relative;
      display: block;
      margin: auto;
       
      &::before {
        content: attr(data-title);
        color: $gray-1-color;
        font-size: 1vw;
        font-family: 'Montserrat';
        box-sizing: border-box;
        position: absolute;
        left:7.5vw;
        bottom: auto;
        right: auto;
        width: 200px;
        opacity: 0;
        transition: all 0.4s ease;
        min-height: 20px;
        top: 50%;
        transform: translate(-0%, -50%);
        z-index: 999;
        display: flex;  
        align-items: center;
      }
      figure{
        position: relative;
        width: 7vw;
        height: 7vw;
       
        overflow: hidden;
        
        img{
          position: absolute;
          left:-2vw;
          top:-.5vw;
          width: 11vw;
          
        }
        p{
          font-size: 18px;
          opacity: 0;
          color: #58595B;
          transition: 0.5s ease;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(-50%, 0);
          height: 100%;
          display: flex;
          min-width: 250px;
          align-items: center;

        }
      }
      &:hover{
        p{
          opacity: 1;
        }
        &::before {
          opacity: 1;
        }
      }
    }
    .swiper-wrapper{
      &:hover{
        a{
          opacity: .1;
          transition: .5s ease;
          &:hover{
            opacity: 1;
            transition: .5s ease;
          }
        }
      } 
    }
  }
}




.midias{
  position: relative;
  
  
  .container{
    max-width: 85%;
  }
  &::before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    
    background-size:  cover !important;
    z-index: 1;
    @include responsive(s){
      background-size:  cover !important;
      background-position: center right;
    }
  } 
  h3{
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 50px;
  }
  .col-md-6{
    position: absolute;
    z-index: 5;
    right: 0;
    @include responsive(s){
      padding: 0 !important;
      margin: 0 !important;
    }
    .boxnoticias, .boxartigos{
      height: 300px;
      position: relative;
      overflow: auto;
      margin:40px 0;
      

      h4{
        color: $primary-color;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 50px;
      }
      a{
        display: block;
        color: currentColor;
        text-decoration: none;
        transition: .5s ease;
        margin-bottom: 20px;
        font-weight: 100 !important;
        @include typeface-2(p1, desktop);
        line-height: 187%;
        @include responsive(s){
          @include typeface-2(p1, mobile);
          line-height: 180%;
        }
        &:nth-of-type(even) {
          color: $gray-2-color;
        }
        &:nth-of-type(odd) {
          color:  $primary-color;
        }
          &:hover{
            text-decoration: underline;
        }
        @media (max-width:991px) {
          margin-bottom: 8px;
          line-height: 140% !important;
        }
        @media (max-width:540px) {
          margin-bottom: 8px;
          line-height: 140% !important;
        }
      }
      .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
        background-color: $primary-color;
        margin-top: 0;
      }
      .mCSB_scrollTools .mCSB_draggerRail{
        display: none;
        
      } 

.midias .col-md-6 .boxartigos .mCSB_scrollTools .mCSB_draggerRail{ display:none;}
.mCSB_scrollTools .mCSB_draggerContainer:before{
content:"";
display:block;
position:absolute;
top:0;
left:50%;
transform: translate(-50%);
    background-color: $primary-color;
height:100%;
width:1px;
}
    }
   
  }
}


#mCSB_1_scrollbar_vertical *{
  opacity: 1 !important
}
.mCSB_scrollTools{
  opacity: 1 !important
}

.formbusca-base{
  margin-left: 1em;
  padding-left: 28px;;
  padding:0 !important;
  position:absolute;
  left:50%;
  margin-left:-600px;

}

.formbusca-detail{
  height:483px;
  display: block;
  img{
    position: absolute;
    right:0;
    width: 900px;
    @media (max-width: 1694px){
      width: 800px !important;
    }
    @media (max-width: 1577px){
      width: 700px !important;
    }
    @media (max-width: 1486px){
      width: 600px !important;
    }
  }
  right: 0;
}
























main {
  background-image: url('../../src/assets/img/background.jpg');
  background-repeat: no-repeat;
  background-size: 100vw;
  section{
    height: 100%;
    
  }
}

.quemsomos{
  height:56vw;
  .about-us{
    width: 50%;
    margin-top: 4vw;
    .col-md-6{
      width: 100%;
    }
    p{
      font-size:.8vw ;
      font-weight: 300;
      margin-left: 10vw;
      margin-right: 5vw;
      margin-bottom: 1vw;
    }
  }
}

.atuacao{
  padding: 0;
  height:56vw;
  text-align: left;
  position: relative;
  .atuacao-conteudo{
    position: absolute;
    right: 0;
    margin:0;
    width: 100%;
    padding: 0;
    width: 50%;
    font-weight: 300;
    p{
      &:nth-of-type(even) {
        color: $gray-2-color;
      }
      &:nth-of-type(odd) {
        color: $primary-color
      }
    }
    p{
      margin:0;
      line-height: 2.8vw;
      @media (max-width: 960px){
        line-height: 2vw;
      }
      a{
        font-size:1.2vw ; 
        @media (max-width: 960px){
          font-size:1vw ;   
        }
      }
    }
    
    .conteudo-1 {
      margin-top:5vw;
      height:20.1vw;
      padding-left: 5vw;
      padding-right: 10vw;
    }
    .conteudo-2 {
      height: 12.5vw;
      transform: translateX(-25%);
      h2{
        font-size:2.8vw;
      }
    }
    .conteudo-3 {
      height:19.2vw;
      padding-left: 5vw;
      padding-right: 10vw;
    }
  }
}


.professional{
  padding: 0;
  height:56vw;
  text-align: left;
  position: relative;
  margin-top:4px;

  .profissionais-watermark{
    display: none;
    li{
      color:#82002A;
      line-height: 1.85vw;
      font-family: 'Montserrat';
      font-size: .9vw;
      opacity: .06;
      z-index: -1;
    }
    position:absolute;
    left:0;
  }

  .formbusca{
    width: 50%;
    margin:0;
    padding: 0;
    padding-left: .8vw;
    margin-left: 10vw;
    margin-top: 8vw;
    h2{
      font-size: 3vw;
    }

    form{
      margin-top:5vw;
      input{
        width: 25vw;
        font-size: 1vw;
        text-align: left;
        padding-left:2vw;
        padding-bottom:.7vw;
      }
      svg{
        height: 1.5vw;
      }
    }
  }

  .swiper-professionals{
    padding:0;
    height: 31vw;

     @media (max-width: 1635px) {
       height:30vw;
      
   }

    // @media (max-width: 1635px) {
    //   height: 25.5vw;
      
    // }

    @media (max-width: 1600px) {
      height:29vw;
      .btsslide {
        top: 13vw;
    }
  }
   
    // @media (max-width: 1500px) {
    //   height: 24.5vw;
      
    // }
    @media (max-width: 1480px){
       height: 28vw;
     }
    // @media (max-width: 1366px){
    //   height: 23.5vw;
    // }
     @media (max-width: 1298px){
       height: 26vw;
      .btsslide {
        top: 12vw;
    }
     }
    // @media (max-width: 1180px){
    //   height: 21.5vw;
    // }
     @media (max-width: 1100px){
       height:25vw;
     }
    

     @media (max-width: 1050px){
       height: 24vw;
       .btsslide {
        top: 11vw;
    }
     }

     @media (max-width: 970px){
      height: 28vw;
    }
    @media (max-width: 850px){
      height: 26vw;
    }
    @media (max-width: 710px){
      height: 26vw;
    }
    @media (max-width: 570px){
      height: 23vw;
    }
    
  }
}


.midias{
  padding: 0;
  height:56vw;
  text-align: left;
  position: relative;
  margin-top:4px;
}

.midias-conteudo{
  width: 50%;
  position: absolute;
  right: 0;
  height:50vw;
  margin-left:4vw;
  .h2{
    font-size: 3.5vw !important;
    margin:0 !important;
    margin-left:3.7vw !important;
    padding:0 !important;
    margin-top:5vw !important;
  }
  a{
    font-size: 1.3vw !important;
    line-height: 3vw !important;
  }
  .news{
    height:20vw;
    margin-left:4vw;
    margin-right:5vw;
    margin-top:3vw !important;
    .h3{
      text-transform: uppercase;
      font-size: 1.8vw !important;
      font-weight: 100;
    }
  }
  .acticles{
    height:20vw;
    margin-left:4vw;
    margin-right:5vw;
    margin-top: 2vw;
    .h3{
      text-transform: uppercase;
      font-size: 1.8vw !important;
      font-weight: 100;
    }
  }
  .mCustomScrollbar{
    height:13vw !important;
    margin:0 !important;
    margin-top:1vw !important
  }
}


.class-id-5-name-bruno-andre-bredda-carrara{
  top: -.3vw !important;
}

.class-id-8-name-adriana-de-moraes-vojvodic{
  left: -2.6vw !important;
}
.class-id-9-name-lucas-esper-berthoud{
  top: -.1vw !important;
  left: -2vw !important;
  width: 11.5vw !important;
}
.class-id-10-name-luis-francisco-jardim{
  top: -.3vw !important;
}
.class-id-12-name-luciana-akemi-moriya{
  top: -.5vw !important;
  left: -2.4vw !important;
  width: 12vw !important;
}
.class-id-13-name-luana-lima-teixeira{
  left: -3.5vw !important;
}
.class-id-14-name-flavio-tambellini-rimoli{
  top: -.3vw !important;
  left: -3vw !important;
  width: 11.5vw !important;
}
.class-id-16-name-vinicius-augusto-vargas-{
  top: -.3vw !important;
}
#quemsomos{
  position: absolute;
  margin-top:-4.1vw;
}
#contato{
  position: absolute;
  margin-top:-3.7vw;
}