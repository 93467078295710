.modal{
  //display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 4vw;
  width: 100%;
  height: 100%;
  overflow: auto; 
  background-color: #FFF !important;
 
  .container{
    width: 100%;
    max-width: initial;
    align-content: center;
    justify-content: flex-start;
    padding: 0 17.4vw;
    position: relative;
    z-index: 200;
    @include responsive(s){
      padding: 0 5rem;
    }

  }
  header{
    background: none;
    position: initial;
    padding: 0 9em;
    
    max-height: initial;
    h2{
      color: $primary-color;
      text-transform: uppercase;
      font-weight: 300;
      margin: 0;
      margin-left:3.1vw;
      font-size: 2vw;
    }
    .container{
      width: 100%;
      padding: 3rem 16rem;
      @include responsive(s){
        padding:3rem 2rem;
        align-items: center;
      }
    }
  }
  .content-modal{

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: scroll;
    // background:$white-color;
    background-repeat:repeat-y ;
    background-size: 7.5%;
    padding-bottom: 150px;
    
    @include responsive(s){
      background-size: 10%;
    }
  }
  &.left{
    .content-modal{
      background-position: left bottom;
    }
  }
  &.right{
    .content-modal{
      background-position: right bottom;
    }
  }
  
  footer{
    
    width: 100%;
    position: fixed;
    z-index: 2;
    bottom: 0;
    .container{
      justify-content: center;
      align-items: start;
      padding: 0 !important;
      flex-direction: column;
    }
  }
  section{
    //padding: 2rem 0;
    min-height: 88vh;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    z-index: 1;
    width:100%;

  }
  h3{
    text-transform: uppercase;
    font-weight: 300;
    color: $primary-color;
    margin-bottom: 1rem;
    font-size:1.8vw;;
  }
  h4{
    font-weight: 800;
    margin-bottom: 25px;
    color: $primary-color;
    font-size:1vw;
  }
  p{
    font-size: 18px;
    line-height: 180%;
    font-size: .8vw;
    margin-right: 5vw;
    a{
      color:$primary-color !important;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .controles{
    *{
      font-family: "Montserrat" !important
    }
    background:$white-color;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    button{
      border:0;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      font-size: 16px;
      transition: .5s ease;
      cursor: pointer;
      img{
        width: 15px;
        height: 24px;
        margin: 0 2rem;
        path, rect{
          fill: $primary-color;
          transition: .5s ease;
        }
      }
      &:hover{
        color:#333;
        svg path, svg rect{fill:#333;transition: .5s ease;}
      }
    }
    .hombt{
      flex-direction: column;
      width: 50px;
      margin: 0 2rem;
      .homeControler{
        background-color: $primary-color;
        width:10px;
        height:10px;
        margin-bottom:10px;
      }
      
    }
  }
}


//Reconhecimento
.reconhecimentomodal{
  p{
    font-weight: 100;
    
  }
  ul li{
    font-weight: 100;
  }
  .insignia-1{
    figure img{
      margin-top:-1vw;
      margin-right:1vw;
      width: 5vw;
      height:  6vw;
    }
  }
  .base-side{
    margin-top:5.5vw;
    display: flex;
    .right-side{
      width: 50%;
      padding:0 !important;
      .chambers img{
        width: 12vw;
        margin-top:5vw
      }
      .p-2{
        margin:0px 1vw !important;
        padding: 0 !important;
        h4{
          margin:.5vw 0;
        }
      }
    }
    .left-side{
      width: calc(50% - 2.5vw);
      border-right:$primary-color solid .1vw;
      margin-right: 2vw;
      height:21.5vw;
      .p-2{
        margin:0px 1vw !important;
        padding: 0 !important;
      }
    }
  }
  section{
    display: block !important;
    margin-top:5vh;
  }
  ul{
    margin:0;
    padding: 0;
    list-style: none;
    margin-right: 2vw;
    li{
      color: $gray-1-color;
      font-size: .8vw;
      font-family: 'Montserrat';
      line-height: 1.8vw;
    }
  }
  .col-md-6{
    height: 500px;
    height:28vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:first-child{
      border-right: 1px solid $primary-color;
    }
    &:last-child{
      padding-left: 2.2vw;
    }
    @include responsive(s){
      min-height: initial;
      text-align: center;
      &:first-child{
        border: 0;
      }
    }
  }
}

//Atuacao
.atuacaomodal, .artigosmodal{
  .boxatuacao{
    padding: 3rem 0;
    border-bottom: 1px solid $primary-color;
    br{
      display: none;
    }
  }
  h3{
    display: flex;
    align-items: center;
    text-transform: initial;
    position: relative;
    @include responsive(l){
      font-size: 2.5rem;
    }
    &::before{
      content: "";
      display: block;
      width: 0.5vw;
      height: 0.5vw;
      background-color: $primary-color;
      margin-right: 11px;
      margin-left: -20px;
      position: absolute;
      top:1.2vw;
    }
    
  }
}


//Profissionais
.profissionalmodal{
    .description-professional{
      margin-top:1.7vw;
      position: absolute;
      left:25vw;
      max-height: 43vw;
      overflow: auto;
      padding-bottom: 4vw;
      h3{
        font-size:2vw !important;
      }
      a{
        font-size:1vw !important;
      }
    }
    .row{
      position:fixed;
      left:0;
      top:100px;
      top:6vw;
    }
    figure{
      width: 20vw;
      margin-left:3vw;
      padding:0 !important;
      margin-top:2vw;
    }
    .profissional-image{
      max-width: 380px;
      padding:0 !important;
      margin:0;
    }
    p, p *{
      color:$gray-2-color !important;
      font-family: "Montserrat";
      line-height: 2vw;
      margin-bottom: 18px;
      font-weight: 300;
      font-size:1vw !important;
    }
    p br{
      display: none;
    }
  p{
    font-weight: 300;
    line-height: 1.9vw !important;
  }
  img{
    margin-top:5px;
    margin-left:20px;
    width: 100%;
    display: block;
    @include responsive(s){
      width: 70%;
    }
  }
  section{
    @include responsive(s){
      width: 80%;
      text-align: center;
    }
  }
  .container{
    background: transparent !important;;
  }
  a{
    color: $primary-color;
    text-decoration: none;
    transition: .5s ease;
    position: relative;
    display: inline-block;
    font-size:18px;
    font-family: 'Montserrat';
    margin-top: 7px;
    top: 0;
    @include responsive(s){
      justify-content: center;
      font-size:16px;
    }
    &:hover{
      top: -5px;
      
    }
  }
  .inlink{
    display: flex;
    align-items: center;
    margin-top: 12px;
    svg{
      max-width: 32px;
      height: auto;
      margin-right: 2.0rem;
      path{
        fill: $primary-color;
      }
    }
    &::after{
      display: none;
    }
  }
  footer{
    .controles{
      margin:0 auto;
    }
  }
}





.hide{
  animation: fadeOut .5s forwards;
  display: none;
}

.show{
  animation: fadeIn .5s forwards;
  display: show;
}

@keyframes fadeIn {
  0% { opacity: 0; visibility: hidden;}
  100% { opacity: 1; visibility:visible;}
}
@keyframes fadeOut {
  0% { opacity: 1; visibility:visible;}
  100% { opacity: 0; visibility: hidden;}
}



.reconhecimento-background{
  background: $white-color url(../assets/img/bg-modal-reconhecimento-right.jpg) repeat-y right bottom !important;
  background-position: right -6.4vw !important;
  background-size: 12vw !important;
}
.artigos-background{
  background: $white-color url(../assets/img/bg-modal-artigos-left.jpg) repeat-y left bottom !important;
  background-size: 12vw !important;
}
.atuacao-background{
  background: $white-color url(../assets/img/bg-modal-atuacao-left.jpg) repeat-y left bottom !important;
  background-position: left 900px !important;
  background-size: 12vw !important;
  
}
.noticias-background{
  background: $white-color url(../assets/img/bg-modal-noticias-left.jpg) repeat-y left bottom !important;
  background-size: 12vw !important;
}
.profissionais-background{
  background: $white-color url(../assets/img/bg-modal-profissionais-left.jpg) repeat-y left bottom !important;
  background-position: 0% 50px !important;
  background-size: 12vw !important;
}