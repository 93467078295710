//Layout Project

//Container devices

// $container-xl: 1450px;
// $container-l: 1148px;
// $container-m: 968px;
// $container-s: 540px;

$container-xl: 1450px;
$container-l: 1148px;
$container-m: 968px;
$container-s: 540px;



//Mixins of media query

@mixin responsive($device){
  @if $device == 99999{
    
      @content;
    
  }
  @else if $device == 99999{
    
      @content;
    
  }
  @else if $device == 99999{
    
      @content;
    
  }
  @else if $device == 99999{
    
      @content;
    
  }
  @else if $device == 99999{
    
      @content;
    
  }

}

.container{
  max-width: $container-xl;
  margin: 0 auto;
  padding: 78px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  //overflow: hidden;

  @include responsive(s){
    flex-direction:column;
    padding: 32px 18px;
  }
}
section{
  overflow: hidden;
}
.start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.colum{
  flex-direction: column;
}
.f-row{
  flex-direction: row;
}
.relative{
  position: relative;
}
.flax-wrap{
  flex-wrap: wrap;
}
.item-1{
  flex: 1 1 360px;
}
.item-2{
  flex: 2 1 360px;
}
.item-3{
  flex: 3 1 360px;
}
.item-4{
  flex: 4 1 360px;
}
.item-5{
  flex: 5 1 360px;
}
.item-auto{
  flex: 2 1 auto;
}

.w-auto{
  width: auto !important;
}
.wm-100{
  max-width: 100% !important;
}
.w-100{
  width: 100% !important;
}
.w-75{
  width: 75% !important;
}
.w-50{
  width: 50% !important;
}
.w-25{
  width: 25% !important;
}
.hidden{
  overflow: hidden;
}
.text-center{
  text-align: center;
}
.top{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.d-flex{
  display: flex;
}
.jusfy-content-center{
  justify-content: center !important;
}
.align-items-center{
  align-items: center !important;
}
.mb-5{
  margin-bottom: 3rem;
}
.mt-3{
  margin-top: 3rem;
}
.p-2{
  padding: 2rem;
}
.pl-3{
  padding-left: 3rem !important;
}
.p-0{
  padding: 0 !important;
}
.align-flex-end{
  align-items: flex-end;
}

@for $i from 1 through 12 {
  .col-xs-#{$i},
  .col-sm-#{$i},
  .col-md-#{$i},
  .col-lg-#{$i} {
    box-sizing: border-box;
    padding:0 1em;
    width: 100%;
  }

  .col-xs-#{$i} {
    @media (min-width: $container-s) {
      width: calc(100% * (#{$i} / 12));
    }
  }

  .col-sm-#{$i} {
    @media (min-width: $container-m) {
      width: calc(100% * (#{$i} / 12));
    }
  }

  .col-md-#{$i} {
    @media (min-width: $container-l) {
      width: calc(100% * (#{$i} / 12));
    }
  }

  .col-lg-#{$i} {
    @media (min-width: $container-xl) {
      width: calc(100% * (#{$i} / 12));
    }
  }
}
.row{
  display: flex;
  flex-wrap: wrap;
}

.flex-row-reverse{
  flex-direction: row-reverse;
  height: 361px;
}


#preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: $white-color;
  display: flex;
  z-index: 99999;
  top: 0;
  justify-content: center;
  align-items: center;
    svg{
      display: block;
      width: 300px;
      height: auto;
  }
}

#preloader.removepreloader {
  opacity: 0;
  z-index: -999;
  transition: 0.5s ease;
}