
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}
 
//Typeface menu
@mixin typeface-menu($size, $device: desktop){
  font-family: 'Montserrat';
  @if $size == menu and $device == desktop{
    font-size: 1.2rem;
    line-height: 20px;
    font-weight: 300;
  }@else if $size == menu and $device == mobile{
    font-size: 16px;
    line-height: 20px;
  }
  
}

@mixin typeface-1($size, $device: desktop){
  font-family: 'Montserrat';
  //H1
  @if $size == h1 and $device == desktop{
    font-size: 5.5rem;
    line-height: 100%;
    font-weight: 400;
  }@else if $size == h1 and $device == mobile{
    font-size: 39px;
    line-height: 120%;
  }

   //H2
   @if $size == h2 and $device == desktop{
    font-size: 5.0rem;
    line-height: 111%;
    font-weight: normal;
  }@else if $size == h2 and $device == mobile{
    font-size: 32px;
    line-height: 120%;
  }

   //H3
   @if $size == h3 and $device == desktop{
    font-size: 3rem;
    line-height: 127%;
    font-weight: 400;
  }@else if $size == h3 and $device == mobile{
    font-size: 25px;
    line-height: 140%;
  }

   //H4
   @if $size == h4 and $device == desktop{
    font-size: 18px;
    line-height: 100%;
  }@else if $size == h4 and $device == mobile{
    font-size: 18px;
    line-height: 120%;
  }
   //H5
   @if $size == h5 and $device == desktop{
    font-size: 25px;
    line-height: 195%;
  }@else if $size == h5 and $device == mobile{
    font-size: 25px;
    line-height: 195%;
  }

  
}

@mixin typeface-2($size: p2, $device: desktop){
  font-family: 'Montserrat';

  //P1
  @if $size == p1 and $device == desktop{
    font-size: 2.2rem;
    line-height: 130%;
  }@else if $size == p1 and $device == mobile{
    font-size: 1.6rem;
    line-height: 180%;
  }

    //P2
    @if $size == p2 and $device == desktop{
      font-size: 17px;
      line-height: 178%;
      margin-bottom: 18px;
    }@else if $size == p1 and $device == mobile{
      font-size: 16px;
      line-height: 130%;
    }
     //P3
     @if $size == p3 and $device == desktop{
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 22px;
    }@else if $size == p3 and $device == mobile{
      font-size: 1.6rem;
      line-height: 130%;
    }
}


h1, .h1{
  @include typeface-1(h1, desktop);

  @include responsive(s){
    @include typeface-1(h1, mobile);
  }
}
h2, .h2{
  @include typeface-1(h2, desktop);

  @include responsive(s){
    @include typeface-1(h2, mobile);
  }
}
h3, .h3{
  @include typeface-1(h3, desktop);

  @include responsive(s){
    @include typeface-1(h3, mobile);
  }
}
h4, .h4{
  @include typeface-1(h4, desktop);

  @include responsive(s){
    @include typeface-1(h4, mobile);
  }
}

p{
  @include typeface-2(p2, desktop);
  font-weight: 300;
  @include responsive(s){
    @include typeface-2(p2, mobile);
  }
}
.bold{
  font-weight: bold;
}
strong{
  font-weight: bold;
}
.align-center{
  text-align: center;
}
.align-left{
  text-align: left;
}
.align-right{
  text-align: right;
}
.text-uppercase{
  text-transform: uppercase;
}