.banner{
  position: relative;
  width: 100%;
  // .swiper-slide {
  //   min-height: 57vw !important;
  // }
  .logobox{
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 5;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    .logo{
      filter: drop-shadow(5px 5px 5px #22222237);
      width: 800px;
      z-index: 5;
      height: 154px;
      transform: scale(80%);
      display: flex;
      @include responsive(s){
        height: 81px;
        width: 331px;
      }
      svg{
        width:145px;
        height: auto;
        margin-right: 54px;
        path{
          fill:$primary-color;
        }
        @include responsive(s){
          margin-right: 15px;
        }
      }
      .logotx{
        font-family: 'Montserrat';
        color: $white-color;
        font-size: 42px;
        text-transform: uppercase;
        max-width: 569px;
        line-height: 54px;
        strong{
          font-weight: 600;
        }
        @include responsive(s){
          font-size: 19px;
          line-height: 27px;
        }
      }
    }
  }
   figure{
    position: relative;
    img{
      width: 100%;
      height: auto;
      @include responsive(s){
        object-fit: cover;
        height: 410px;
      }
    }
    .lds-ripple{
      position: absolute;
      left:50%;
      margin-left:-24px;
      top:20vw;
    }
  }
  .mouse {
    border: 1px solid $white-color;
    border-radius: 40px;
    bottom: 60px;
    display: block;
    height: 46px;
    left: 50%;
    position: absolute;
    width: 26px;
    transition: 0.5s ease;
    text-decoration: none;
    transform: translate(-50%, 0);
    z-index: 8;
    @include responsive(s){
      width: 22px;
      height: 34px;
    }
    &::before{
      content: "";
      font-size: 1rem;
      text-transform: uppercase;
      color: #FFFFFF;
      font-family: 'Montserrat';
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translate(-50%, 0);
      letter-spacing: 1px;
      text-shadow: 0 3px 6px #000;
    }
    &::after{
      content: "";
      display: block;
      width: 0; 
      height: 0; 
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 0);
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid $white-color;
      @include responsive(s){
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #FFFFFF;
      }
    }
    &:hover{
      bottom: 50px;
    }
  }
  .mouse span {
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-name: scroll;
      background: none repeat scroll 0 0 $white-color;
      border: 1px solid transparent;
      border-radius: 4px;
      display: block; 
      height: 4px;
      margin: 6px auto;
      width: 4px;
  }
  @keyframes scroll {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
  }

 .swiper-pagination{

    @media (max-width: 1010px){
        display: none;
    }
    bottom: 10vw;
    .swiper-pagination-bullet{
      width: 10px;
      height: 10px;
      background-color: #FFF;
      opacity: 1;
      &.swiper-pagination-bullet-active{
        background-color: $primary-color;
      }
    }
    @include responsive(s){
      bottom: 138px;
    }
 }
}