.txtMobile{
  display: none;
}
.btsslide span{
  display: none;
}
@media (max-width: 991px){
.txtDesktop{
  display: none;
}
.btsslide span{
  display: block;
}


//Header
header {
  height: auto !important;
  padding: 3vw;
  width: auto;
  z-index: 1004;
  
  .navbar-desktop{
    display: none;
  }
  .navbar-mobile{
    display: flex;
    padding: 0;
    position: relative;
    z-index: 5;
    flex-direction: column;
    align-items: center;
    min-width: 10vw;
    .logo{
      width: 6vw;
    }
  }
  .menu-mobile {
    position: fixed;
    z-index: 3;
    left: -40vw;
    top: 0;
    margin-top: 0;
    width: 40vw;
    transition: .5s ease;
    ul{
    margin: 2vw 0;
    padding:1vw 4vw;
    &:last-child{margin:0;}
    &:first-child{margin:0;}
  }
  &.active-menu-mobile{
    left: 0;
    top: 0;
    width: 40vw;
    padding-top: 13vw;
    padding-bottom: 4vw;
  }
  .nav-mobile li a{
    padding-left: 0;
    padding-right: 0;
  }
}
 
}
header .navbar-mobile .active-bx{
  margin: 0;
}
header li a::before, header li button::before{
  display: none;
}
.menu-item a, .menu-item button {
  font-size: 3vw !important;
  padding: 1vw 0;
}
main{
  background: none;
}
// Banner
.banner .mouse{
  display: none;
}
.banner figure {
  font-size: 0;
  img {
    width: 100%;
    height: 100vw;
    object-fit: cover;
  }
}
.banner .swiper-pagination{
  display: block;
}
//Frase
.frase{
  display: flex;
  padding: 9vw 8vw;
  background: url(../assets/mobile/barras-bp.png) no-repeat right;
  background-size:50% 100%;
  h1.h3{
    width: 50%;
    font-size: 4vw;
    text-align: left;
    height: auto;
  }
}

//Quem somos
.quemsomos{
  height: auto;

  .about-us{
    width: 100%;
    margin: 0;
    background-color: $gray-3-color;
    padding: 9vw 8vw;
    p{
      font-size: 3.1vw;
      margin: 0;
      margin-bottom: 5vw;
      &:last-child{
        margin-bottom: 0;
      }
      
      }
      .txtMobile{
        display: block !important;
        p{
          &:last-child{
            margin-bottom: 5vw;
          }
        }
    }
    .col-md-6{
      padding: 0;
    }
  }
}

//Areas de atuacao
.atuacao {
  background: url(../assets/mobile/bg-areas-atuacao.png) no-repeat center;
  background-size: cover;
  padding: 9vw 8vw;
  height: auto;
  h2{
    font-size: 5vw !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
      text-align: left;
      align-items: flex-start;
  }
  .atuacao-conteudo {
    position: initial;
    width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(0, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    .row{
      &.conteudo-2{
        grid-area: 1 / 1 / 2 / 2; 
        transform: initial;
        height: auto;
      }
      &:first-child{
        
        grid-area: 1 / 2 / 3 / 3; 
      }
      &:last-child{
        grid-area: 2 / 1 / 3 / 2; 
      }
      div{
        height: auto;
        padding: 0;
        margin: 0;
      }
      .conteudo-1{padding-left: 2vw;}
    }
    p {
      a{
        font-size: 3.1vw;
        line-height: 4vw;
        margin-bottom: 3vw;
        display: block;
      }
    }
  }
}
//Profissionais
.professional{
  margin: 0;
  background:  url(../assets/mobile/bg-atuacao.png) no-repeat top right;
  background-size:cover;
  height: auto;
  .formbusca{
    width: 100%;
    padding: 6vw 8vw;
    margin: 0;
    h2{
      font-size: 5vw !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
      text-align: right;
    }
    .form{
      width: 100%;
      input{
        width: 100%;
        font-size: 3vw;
      }
      button{
        max-width: 24px;
      }
    }
  }
  .swiper-professionals{
    height: auto;
    display: block;
  }
  .btsslide{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 4vw;
    width: 100%;
    position: initial;
    span{
      font-family: 'Montserrat';
      padding: 0 3vw;
    }
  }
  .swiper-button-prev, .swiper-button-next{
    height: auto;
    position: initial;
    width: auto;
    &::after{
      height: 5vw;
      width: 3vw;
    }
  }
  .swiper{
    width: 100%;
    margin-bottom: 5vh;
    .swiper-slide{
      text-align: center;
      font-size: 18px;
    
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide:first-child {
      margin-left: 5vh;
    }
  
  
    a{
      display: flex;
    flex-direction: column-reverse;
    }
    a figure {
        position: relative;
    width: 12vh;
    height: 11vh;
    overflow: hidden;
    
    
}
    a figure img{
      width: 100% !important;
      object-fit: cover;
      object-position: center -27px;
      position: initial;
      height: 33vh;
    }
    a::before{
          position: initial;
    margin: 0;
    width: 100%;
    text-align: center;
    transform: initial;
    font-size: 1.5vh;
    opacity: 1;
    margin-top: 17px;
    }
  }
 
}

.professional .swiper .item-swipper-6 a::before, .professional .swiper .item-swipper-14 a::before, .professional .swiper .item-swipper-15 a::before, .professional .swiper .item-swipper-7 a::before{
  width: 100% !important;
  text-align: center !important;

}
.professional .swiper .item-swipper-12 a figure img{
  width: 123% !important;
  object-position: -82px -22px !important; 
}
.professional .swiper .item-swipper-13 a figure img{
  width: 123% !important;
  object-position: -71px -29px !important; 
}
//Midias
.midias{
  margin: 0;
  padding: 6vw 8vw;
  background: url(../assets/mobile/bg-noticia.png) no-repeat center;
  background-size: cover;
  height: auto;
  overflow: initial;
  .col-md-6{
    position: relative;
    height: auto;
    margin: 0;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    h3.h2 {
      grid-area: 1 / 1 / 2 / 2;
      font-size: 5vw !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
     }
    .news {
      grid-area: 1 / 2 / 2 / 3;
      margin-right: 0;
      height: 35vw !important;
      margin-top: 0 !important;
      margin-bottom: 4vw;
      h4.h3{
        font-size: 4vw !important;
      }
      
    }
    .acticles {
      grid-area: 2 / 2 / 3 / 3;
      margin-right: 0;
      height: 35vw !important;
      margin-top: 0 !important;
      h4.h3{
        font-size: 4vw !important;
      }
    }
    .boxnoticias, .boxartigos{
      a{
        font-size: 3vw !important;
        margin-bottom: 2vw;

      }
    }
  }
}
.midias-conteudo .mCustomScrollbar{
  height: 27vw !important;
}
.midias::before{
  display: none;
}
.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px !important;
  margin: 0 3px;
  height: 14px;
}

//Rodape
.rodape{
  height: auto;
  padding: 7vw 8vw;
  background: url(../assets/mobile/bg-contato.png) no-repeat right top;
  background-size:50vw;
  overflow: initial; 
  margin: 0;
  background-color: $gray-3-color;
  .col-md-6{
    padding: 0;
  }
  .contacto{
    margin: 0;
    width: 100%;
    padding: 0;
    position: relative;
    overflow: initial;
    margin-bottom: 5vw;
    h1{
      font-size: 5vw !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
    h3{
      font-size: 3vw;
      margin: 3vw 0px;
      line-height: 4vw;
    }
    p{
      font-size: 3vw;
      max-width: 60%;
      .btmail{
        margin-top: 2vw !important;
    display: block;
      }
    }
    a{
      font-size: 3vw;
    }
    .linkedinlink{
      position: relative;
      margin: 0;
      max-width: initial;
      float: right;
      margin-top: -25px;
      a{
        width: 5vw;
        height: 5vw;
        background: url(../assets/mobile/linkedin-icon.png) no-repeat center;
        background-size: cover;
        text-indent: -999px;
        position:absolute;
        right: 0;
        bottom: 0;
        svg{
          display: none;
        }
      }
    }
  }
  .copy {
    position: absolute;
    width: 100%;
    height: 8vw;
    left: 0;
    bottom: 0;
    ul {
      justify-content: center;
    li{
      &:first-child{
        display: none;
      }
      &:last-child{
        display: none;
      }
      a{
        font-size: 2vw !important;
        &::before{
          border-left: 1vw solid transparent;
          border-right: 1vw solid transparent;
          border-bottom: 2vw solid #82002a;
        }
      }
    }
  }
  }
}



//Modal
.modal{  
  top: 0;
  header{
    width: 100%;
    height: auto !important;
    padding: 0;
    
    .container{
      padding: 9vw 0;
      h2{
        margin-left: 0;
      font-size: 5vw;
      }
    }
    
  }
  section{
    justify-content: flex-start;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: initial;
  }
  .container{
    padding: 0;
  }
  h3{
    font-size: 3.8vw;
  }
  h4{
    margin-bottom: 7px;
    font-size: 3.1vw;
  }
  p{
    font-size: 3.1vw !important;
    margin: 0;
    margin-bottom: 5vw;
    line-height: 5vw !important;
  }
  
  .content-modal{
    background:url(../assets/mobile/textura.png) repeat-y left bottom !important;
    background-size: 16vw !important;
    padding-left: 20vw;
    padding-right: 5vw;
    overflow: initial;
  }


  //- reconhecimento
  .reconhecimentomodal section, .reconhecimentomodal .base-side{
    margin: 0;
  }
  .reconhecimentomodal  {
    .base-side{
      .left-side{
        width: 100%;
        height: auto;
        margin: 0;
        border-right: 0;
        border-bottom: #82002a solid 0.1vw;
        padding-bottom: 6vw;
        margin-bottom: 6vw;
        .jusfy-content-center {
          justify-content: left !important;
          align-items: flex-start !important;
        }
        
      }
      .right-side{
        width: 100%;
        height: auto;
        margin: 0;
        .insignia-1 {
          border-bottom: #82002a solid 0.1vw;
          padding-bottom: 6vw;
        margin-bottom: 6vw;
        }
        .chambers img {
          width: 31vw;
          margin-top: 5vw;
      }
      }
    }
    .insignia-1 figure img {
      margin-top: 0;
      margin-right: 1vw;
      width: 23vw;
      height: auto;
  }
  ul{
    li{
      font-size: 3.1vw;
    line-height: 6.8vw;
    }
  }
  }

  //Profissionais
  .profissionalmodal{
    .row{
      position:initial;
      flex-direction: column;
      .col-md-3{
        padding: 0;
      }
      figure{
        width: 100%;
        margin: 6vw 0;
      }
      .description-professional{
        position: initial;
        overflow: initial;
        max-height: initial;
        margin: 0;
        h3{
          font-size: 3.5vw !important;
        }
        a{
          font-size: 3.1vw !important;
    margin: 0;
    margin-bottom: 5vw;
    line-height: 5vw !important;
        }
      }
    }
    .profissional-image{
          object-fit: cover;
      height: 45vw !important;
      object-position: 0 -4vw;
      max-width: initial;
    }
  }

//Artigos

.artigosmodal .boxatuacao, .atuacaomodal .boxatuacao{
  margin-bottom: 6vw;
  padding-bottom: 6vw;
  padding-top: 0;
}
.atuacaomodal h3::before, .artigosmodal h3::before{
    width: 1vw;
    height: 1vw;
    top: 2vw;
}


  footer{
    left: 20vw;
    width: 77%;
  //Controlers
  .controles{
    padding: 6rem 0;
    button{
      font-size: 14px;
    }
    .hombt{
      margin: 0;
    }
  }
}
}


}

