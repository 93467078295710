//Footer CSS

.rodape{
  .contacto{
    width: 60%;
    padding-left: 8vw;;
  }
  padding: 0;
  height:52vw;
  text-align: left;
  position: relative;
  margin-top:4px;

  a{
    color: $primary-color;
    text-decoration: none;
    position: relative;
    display: inline-block;
    top: 0;
    &:hover{
      top: -5px;
    }
  }
  .btfone{
    margin-top: 2vw !important;
    padding-left: .8vw;
    color: $gray-1-color;
  } 
  .btmail{
    padding-left: .8vw;
    margin-top:-1vw !important;
  }
  .inlink{
    display: flex;
    margin-top: 6rem;
    align-items: center;
    padding-left: .8vw !important;
    svg{
      max-width: 32px;
      height: auto;
      margin-right: 1vw;
      path{
        fill: $primary-color;
      }
    }
    &::after{
      display: none;
    }
  }
  h1{
    color: $primary-color;
    font-weight: 300;
    font-size: 3vw;;
    margin-top:3.5vw;
    margin-left:.8vw
  }
  h3{
    color: $primary-color;
    font-weight: 300;
    font-size: 1.8vw;;
    margin:2.8vw .8vw;
  }
  .noline{
    margin:1vw 0;
    @media (max-width:540px) {
      line-height: 98%;
    }
  }
  .btfone, .btmail{
    font-size:1.3vw;
    margin:0;
    margin-top:1vw;
  }
  .endereco{
    display: flex;
    p{
      margin:0;
      font-size:1.3vw
    }
    .icone{
      background: url(../assets/img/pin.png) no-repeat center;
      background-size:  90% !important;
      height:7vw;
      width: 4vw;
      margin-left: -5vw;
      margin-right: 1.8vw;
      
    }
    .dados{
        display: flex;
        flex-direction: column;
    }
  }
  

  .inlink{
    margin:0;
    margin-top:3vw;
    @media (max-width: 820px){
      margin-top:-2vw;
      p{
        line-height: 0 !important
      }
    }
    font-size: 1vw;;
    svg{
      width: 1.5vw;
      height: 1.5vw;
    }
  }







  .copy{
    height: 5vw;
    background-color: $white-color;
    padding:1vw 5vw; 
    display: flex;
    align-items: center;

    ul{
      display: flex;
      list-style: none;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      @include responsive(s){
        align-items: center;
        flex-direction: column;
      }
      li{
        @include typeface-menu(menu, desktop);
        @include responsive(s){
          @include typeface-menu(menu, mobile);
        }
        p{
          font-size: 1vw !important;
        }
        text-transform: uppercase;
        width: 10%;
        &:first-child{
          width: 40%;
        }
        &:last-child{
          width: 40%;
          text-align: right;
        }
        @include responsive(s){
          align-items: center;
          text-align: center !important;
          width: 100% !important;
          &:nth-child(1){
            order: 2;
          }
          &:nth-child(2){
            order: 1;
            margin-bottom: 15px;
          }
          &:nth-child(3){
            order: 3;
          }
        }
      }
      a{
        font-size: .6vw !important;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        text-decoration: none;
        &::before{
          content: '';
          width: 0;
          height: 0;
          margin-bottom:.1vw;
          border-left: .3vw solid transparent;
          border-right: .3vw solid transparent;
          border-bottom: .3vw solid $primary-color;
        }
      }
    }

  }

}  

.voltartopo{
  font-size:1vw !important
}