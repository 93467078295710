@charset 'utf-8';

*, *::after, *::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  } 
  

  @import "partials/colors";
  @import "partials/layout";
  @import "partials/tipography"; 
  @import "partials/menu"; 
  @import "partials/banner";   
  @import "partials/footer";   
  @import "partials/button";   
  @import "partials/index";    
  @import "partials/modal";   
   
  
  *{
    margin:0;
    padding:0;
  }
  a{
    transition: .5s ease;
  }
  html{
    scroll-behavior: smooth;
    font-size: 62.5%;
  } 
  body{
    width: 100%;
    height: 100vh;
    position: relative;
    scroll-behavior: smooth;
    font-size: 62.5%;
    font-size: 1.4rem;
    color: $gray-1-color; 
    &.modal-body{
      overflow: hidden;
    }
  }  

  a{
    cursor: pointer;
  }

  .cursor{
    cursor: pointer;
  }
  @import "partials/responsive";  