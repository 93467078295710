//Header desktop
.header-alpha{
  opacity: .9  !important;
}
header{
  width: 100%;
  height: 4vw;
  @media (max-width: 700px){
    height:5vw !important;
    padding: 0 0;
  }
  background-color: $primary-color;
  z-index: 999;
  padding:1vw 0;
  
  position: fixed;

  transition: .5s ease;
  animation-name: activeHeader;
  animation-duration: 1s;
  @include responsive(s){
    padding: 0 0;
    
  }
  //Nav Desktop
  .navbar-desktop{
    margin: 0 auto;
    padding:0 4.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include responsive(m){
      display: none;
    }
    @include responsive(s){
      display: none;
    }
    .navbar-desktop-item{
      flex: 1 1 auto;
      display: flex;
      justify-content:space-between;
      align-items: center;
      .logo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10vw;
        img{
          width: 100%;
          height: auto;
        } 
        svg{
          width: 100%;
          height: auto;
        }
      }
      .menu-desktop{
        list-style: none;
        display: flex;
        margin: 0;
      }
    }
  }
  
  .navbar-mobile{
    max-width: $container-xl;
    margin: 0 auto;
    padding:0 4.8rem;
    display: none;
    @include responsive(m){
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
    }
    @include responsive(s){
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      padding: 15px 2.8rem;
      z-index: 500;
      position: relative;
  }
    .logo{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1vw ;
      img{
        width: 100%;
        height: auto;
      }
    }
    .bx{
      width: 36px;
      height: 26px;
      position: relative;
      cursor: pointer;
      z-index: 800;
      margin: 11px 0;
      transition: .5s ease;
      .bx-bar{
        width: 90%;
        height: 3px;
        background-color: $white-color;
        transition: .7s ease;
        display: block;
        margin: 4px 0;
      }
      .bx-bar-1{
        // content: "";
        top: 40%;
        left: 17%;
    }
      .bx-bar-2{
          // content: "";
          top: 64%;
          left: 17%;
      }
    }
    .active-bx{
      width: 36px;
      height: 36px;
      position: relative;
      cursor: pointer;
      position: absolute;
      right: -26vw;
      top: -3vw;
      z-index: 800;
      .bx-bar-0, .bx-bar-4{opacity: 0;}
      .bx-bar-1{
          // content: "";
          position: absolute;
          top: 50%;
          left: 17%;
          width: 70%;
          height: 2px;
          transform: rotate(-135deg);
          background-color: $white-color;
          transition: .7s ease;
      }
      .bx-bar-2{
          // content: "";
          position: absolute;
          top: 50%;
          left: 17%;
          transform: rotate(315deg);
          width: 70%;
          height: 2px;
          background-color: $white-color;
          transition: .7s ease;
      }
    }
  }
  li{
    a, button{
      cursor: pointer;
      @include typeface-menu(menu, desktop);
      @include responsive(s){
        @include typeface-menu(menu, mobile);
        padding: 1rem;
        &:hover, &:target{
          margin-left: 15px;
        }
      }
      background: transparent;
      font-style: normal;
      border: 0;
      font-weight: 400;
      text-transform: uppercase;
      padding: 0 1rem;
      color: $white-color;
      transition: .5s ease;
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      letter-spacing: 0.36px;
      &::before{
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        background-color: $white-color;
        margin-right: .9rem;
        opacity: 0;
        transition: .5s ease;
      }
      &:hover, &:target{
        letter-spacing: 0;
        font-weight: 800;
        &::before{
          opacity: 1;
        }
      }
      @include responsive(l){
        padding: 0 .5rem;
        font-size: 2.2vw;
      }
    }
    a.active, button.active{
      letter-spacing: 0;
      font-weight: 800;
      &::before{
        opacity: 1;
      }
      @include responsive(s){
          margin-left: 15px;
      }
    }
  }
}

.menu-mobile{
  width: 100%;
  //height: 100vh;
  background-color: $primary-color;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  //transform:translate3d(0, -76px, 0);
  position: relative;
  transition: 1s ease;
  z-index: 3;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  padding:  0;
    width: 100%;

  }

}
.active-menu-mobile{
    opacity: 1;
    pointer-events: all;
    transition: 1s ease;
    padding-top: 70px;
    margin-top: -70px;
    z-index: 0;
}
.active-header{
  max-height: 68px;
  animation-name: activeHeaderFade;
  animation-duration: 1s;
  opacity: .7 !important;
}

@keyframes activeHeaderFade {
  from {opacity: 1 !important;}
  to {opacity: .8 !important;}
}

@keyframes activeHeader {
  from {opacity: .8 !important;}
  to {opacity: 1 !important;}
}



.logo{
  svg{
    width:35px;
    height: auto;
    margin-right: 12px;
  }
  .logotx{
    font-family: 'Montserrat';
    color: $white-color;
    font-size: 9px;
    text-transform: uppercase;
    max-width: 133px;
    line-height: 12px;
    strong{
      font-weight: 600;
    }
  }
}

.menu-item{
  a, button{
    font-size: .7vw !important;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 890px){
      font-size: .3vw !important;
    }
  }
}
.navbar-desktop-item{
  img{
    width: 2vw;
    height: 2vw;
  }
}
